<!--
 * @Description: 留资场景中隐私协议
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-10-11 20:29:17
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/home/components/agreement.vue
-->
<template>
  <div class="sign-popup" @click="shows = false">
    <div class="sign" @click.stop>
      <div class="sign-text">隐私协议</div>
      <div class="sign-content">
        <div v-html="text"></div>
      </div>
      <div class="sign-btn" @click="handleSave">我已阅读并同意协议</div>
      <div class="sign-close" @click="shows = false">
        <img src="../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      text: `
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">感谢您浏览本网站相关信息</span><span>!</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>1</span><span style="font-family: 宋体">、本协议是用户（“您”）与本网站所订立的契约，为方便您深入了解业务及提交需求等，本网站将为您提供更加个性化的留言表单服务，您在提交留言表单时，我们可能会收集和使用您的相关信息。我们非常重视用户信息的保护，我们将按照法律法规要求，采取相应的安全保护措施。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>2</span><span style="font-family: 宋体">、除非您已充分阅读、理解并同意接受和遵守本协议，否则，请勿提交留言表单。</span> <span style="font-family: 宋体">您如果继续提交即表示接受本协议，本协议即在您与本网站之间产生法律效应。</span>
</p>
<p>
    <br/>
</p>
        `,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleSave() {
      this.shows = false;
      this.$emit("on-change");
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .sign {
    width: calc(516px + 162px);
    height: calc(430px + 72px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-tag {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
      font-weight: normal;
      color: #3b3b3b;
      line-height: 24px;
      padding-left: 27px;
      margin-top: 27px;
      span {
        color: #4e6bef;
        font-weight: bold;
      }
    }
    &-content {
      width: calc(516px + 162px - 54px);
      height: 320px;
      overflow: hidden;
      margin: 15px auto;
      background: #f8faff;
      border: 1px solid #d9e0ed;
      overflow-y: auto;
      div {
        padding: 20px;
        font-size: 13px;
        color: #3b3b3b;
        line-height: 22px;
      }
    }
    &-tip {
      font-size: 14px;
      color: #7c7f97;
      padding-left: 27px;
      line-height: 24px;
    }
    &-btn {
      width: 272px;
      height: 37px;
      background: #4e6bef;
      border-radius: 8px;
      margin: auto {
        top: 25px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
