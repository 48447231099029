<!--
 * @Description: 案例分页
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-27 21:04:30
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/home/components/case.vue
-->
<template>
  <div class="case-swiper">
    <div class="swiper-container" ref="cur" id="container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="cell-name">某工业品企业</div>
          <div class="cell-text">
            上线第二个月成交了20万的项目，地域精准搜索词都处于霸屏的模式，一年<br />后，直接单独成立销售公司专门用来转化抖音搜索的线索。
          </div>
        </div>
        <div class="swiper-slide">
          <div class="cell-name">某家电维修企业</div>
          <div class="cell-text">
            秋冬季节家电维修行业，需要大量线索维系夏季高爆发维修服务。合作后每<br />天收到40条以上线索，夏季高峰期达到每天100条以上，平滑度过淡季，迎来<br />高峰。
          </div>
        </div>
        <div class="swiper-slide">
          <div class="cell-name">某上市房产企业</div>
          <div class="cell-text">
            其官网网站在AIGC系统上线3个月后流量48%增长；目前AIGC研发能力以及商业<br />化能力已经达到全国第一梯队，踏准了AI风口，获得了资本市场认可。
          </div>
        </div>
      </div>
    </div>
    <div class="case-pagination">
      <div class="pagination-item swiper-button-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="pagination-item swiper-button-next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      mySwiper: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.mySwiper = new Swiper(this.$refs.cur, {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      });
    });
  },
  methods: {
    handlePagination(type) {
      console.log(this.mySwiper);
      switch (type) {
        case "prev":
          this.$refs.cur?.prev();
          break;
        case "next":
          this.$refs.cur?.next();
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.case-swiper {
  width: 575px;
  height: 193px;
  background-color: #fff;
  position: relative;
  .case-pagination {
    width: 110px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: -30px;
    z-index: 100;
    .pagination-item {
      width: 38px;
      height: 38px;
      background: #ffffff;
      box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #333333;
      //   margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
    .swiper-button-next:after {
      display: none;
    }
    .swiper-button-prev:after {
      display: none;
    }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
  .swiper-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .cell-name {
      font-size: 22px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      margin-left: 40px;
      margin-top: 28px;
    }
    .cell-text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
      margin-left: 40px;
      margin-top: 16px;
    }
  }
}
</style>
