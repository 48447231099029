<!--
 * @Description: 首页
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-23 13:41:49
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/home/index.vue
-->
<template>
  <div
    class="content"
    id="content"
    :style="isPC() ? 'zoom:' + screenWidth / 1440 : ''"
  >
    <div class="content-head">
      <div class="head-view">
        <div class="head-left">
          <div class="head-image">
            <img src="../../../assets/index/logo.png" />
          </div>
          <div class="head-menu">
            <div
              :class="['menu-item', { 'item-active': screenIndex === 0 }]"
              @click="goAnchorParent('container', 0)"
            >
              <div class="item-text">首页</div>
            </div>
            <div
              :class="['menu-item', { 'item-active': screenIndex === 1 }]"
              @click="goAnchorParent('classify', 1)"
            >
              <div class="item-text">产品</div>
            </div>
            <div
              :class="['menu-item', { 'item-active': screenIndex === 2 }]"
              @click="goAnchorParent('case', 2)"
            >
              <div class="item-text">客户案例</div>
            </div>
            <div
              :class="['menu-item', { 'item-active': screenIndex === 3 }]"
              @click="goAnchorParent('price', 3)"
            >
              <div class="item-text">价格</div>
            </div>
            <div
              :class="['menu-item', { 'item-active': screenIndex === 4 }]"
              @click="goAnchorParent('about', 4)"
            >
              <div class="item-text">关于我们</div>
            </div>
          </div>
        </div>
        <div class="head-right">
          <div class="right-item" @click="goPages('Agent')">成为代理商</div>
          <div class="right-item" @click="goPages('Login')">登录</div>
        </div>
      </div>
    </div>
    <div style="height: 70px"></div>
    <div class="swiper-container" ref="cur" id="container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            src="https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/image/index_banner_one.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/image/index_banner_two.png"
          />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
    <div class="content-classify" id="classify">
      <div class="classify-title content-title">核心产品能力</div>
      <div class="classify-list">
        <div class="classify-item">
          <div class="item-icon">
            <img src="../../../assets/index/classify1.png" />
          </div>
          <div class="item-name">内容智能生产</div>
          <div class="item-text">
            先进的AIGC及深度学习算法模型，首创关键词<br />生成视频/图文技术，无限不重复混剪。
          </div>
          <div class="item-x"></div>
          <div class="item-cell">
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">智能图片生成</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">视频智能量产</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">日更，快速起量</div>
            </div>
          </div>
        </div>
        <div class="classify-item">
          <div class="item-icon">
            <img src="../../../assets/index/classify2.png" />
          </div>
          <div class="item-name">多账号矩阵</div>
          <div class="item-text">多账号矩阵运营，全域海量覆盖目标用户</div>
          <div class="item-x"></div>
          <div class="item-cell">
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">蓝V矩阵</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">素人账号矩阵（小红书）</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">智能接待</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">多账号/跨平台管理</div>
            </div>
          </div>
        </div>
        <div class="classify-item">
          <div class="item-icon">
            <img src="../../../assets/index/classify3.png" />
          </div>
          <div class="item-name">搜索霸屏</div>
          <div class="item-text">
            行业首创内容平台搜索结果霸屏，全面覆盖销<br />售区域
          </div>
          <div class="item-x"></div>
          <div class="item-cell">
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">行业精准关键词</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">关键词拓展</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">分地域覆盖</div>
            </div>
          </div>
        </div>
        <div class="classify-item">
          <div class="item-icon">
            <img src="../../../assets/index/classify4.png" />
          </div>
          <div class="item-name">数据赋能</div>
          <div class="item-text">
            基于内容矩阵对企业内容营销提供分析、评估<br />和策略
          </div>
          <div class="item-x"></div>
          <div class="item-cell">
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">内容矩阵分析</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">用户人群分析</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">线索分析</div>
            </div>
            <div class="cell">
              <div class="cell-icon">
                <img src="../../../assets/index/select.png" />
              </div>
              <div class="cell-text">多账号/跨平台分析</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-case" id="case">
      <div class="case-title content-title">客户案例</div>
      <div class="case-text">
        覆盖互联网电商、房产、汽车、家居家装、服务行业、工业品行业、机械行业、人力资源行业等众多领域!
      </div>
      <div class="case-content">
        <div class="case-item">
          <div class="item-name">客户有效的推广是我们<br />坚定的目标</div>
          <div class="item-x"></div>
          <div class="item-text">从客户真实需求出发“简单 精准 高效 智能”</div>
        </div>
        <CaseView />
      </div>
      <!-- <div class="case-list">
        <div class="case-cell">
          <div class="case-item">
            <div class="item-name">客户有效的推广是我们<br />坚定的目标</div>
            <div class="item-x"></div>
            <div class="item-text">
              从客户真实需求出发<br />简单 精准 高效 智能
            </div>
          </div>
          <div class="cell">
            <div class="cell-name">某工业品企业</div>
            <div class="cell-text">
              上线第二个月成交了20万的项目，地域精准搜索词都处于霸屏的模式，一年后，直接单独成立销售公司专门用来转化抖音搜索的线索。
            </div>
            <div class="cell-icon">
              <img src="../../../assets/index/case2.png" />
            </div>
          </div>
          <div class="cell">
            <div class="cell-name">某家电维修企业</div>
            <div class="cell-text">
              秋冬季节家电维修行业，需要大量线索维系夏季高爆发维修服务。合作后每天收到40条以上线索，夏季高峰期达到每天100条以上，平滑度过淡季，迎来高峰。
            </div>
            <div class="cell-icon">
              <img src="../../../assets/index/case2.png" />
            </div>
          </div>
          <div class="cell">
            <div class="cell-name">某上市房产企业</div>
            <div class="cell-text">
              其官网网站在AIGC系统上线3个月后流量48%增长；目前AIGC研发能力以及商业化能力已经达到全国第一梯队，踏准了AI风口，获得了资本市场认可。
            </div>
            <div class="cell-icon">
              <img src="../../../assets/index/case2.png" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="case-image">
        <img src="../../../assets/index/case3.png" />
      </div>
    </div>
    <div class="content-price" id="price">
      <div class="price-title content-title">产品价格</div>
      <div class="price-list">
        <div class="price-item" v-for="item in list" :key="item.id">
          <div
            :class="[
              'item-head',
              {
                'item-head1': item.title.includes('高级'),
                'item-head2': item.title.includes('旗舰'),
              },
            ]"
          >
            {{ item.title }}
          </div>
          <div class="item-cell">
            <div class="cell">
              <div class="cell-label">生成视频条数（个）</div>
              <div class="cell-value">
                {{ Number(item.video_num).toLocaleString() }}
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">绑定账号数（个）</div>
              <div class="cell-value">{{ item.all_account }}</div>
            </div>
            <div class="cell">
              <div class="cell-label">关键词拓词</div>
              <div class="cell-value">
                <div class="value-icon">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">关键词词库提供</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.marketing_tool === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">蓝V账号认证辅助</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.v_account_auth === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">员工账号认证辅助</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.staff_account_auth === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">蓝V账号装修</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.v_account_renovation === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">数据监测后台</div>
              <div class="cell-value">
                <div
                  class="value-icon"
                  v-if="item.data_monitoring_backend === 2"
                >
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">AIGC工具培训</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.aigc_tool === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
            <div class="cell">
              <div class="cell-label">抖音SEO培训</div>
              <div class="cell-value">
                <div class="value-icon" v-if="item.tiktok_seo === 2">
                  <img src="../../../assets/icon/check.png" alt="" />
                </div>
                <div class="value-icon" v-else>-</div>
              </div>
            </div>
          </div>
          <div class="item-x"></div>
          <div class="item-price">
            ¥<span>{{ item.price_yuan }}</span
            >/{{ item.cycle_desc }}
          </div>
          <div class="item-btn" @click="formState = true">立即咨询</div>
        </div>
      </div>
      <div class="price-illustrate">
        1、绑定账号数：即最大支持可绑定的抖音账号数量<br />
        2、生产视频条数：即最大可生产数，根据客户所准备的账号时间、数量相关<br />
        3、员工账号：并非指员工个人账号，是抖音基于企业蓝V账号下的一种账号类型；高级版及以上会赠送员工账号发布，企业可选择是否使用。<br />
      </div>
    </div>
    <div class="content-about" id="about">
      <div class="about-title content-title">关于我们</div>
      <div class="about-text">
        云鼎汇富
        （ABoostify），作为全球领先的AI智能营销云平台，致力于帮助企业实现卓越增长。我们以先进的MarsightAl-Engine智<br />
        能创意技术方案为枝心，为全球互联网内容商业生态提供多元化的解决方案。从市场洞察、Al内容制作、自动化投放、效果回收．<br />
        远营优化、数字化协作
        我们一站式解决方索助力客户轻松实现商业目标。搭建在“AI Boost and Amplify
        your Business！”理念之上，云<br />
        鼎汇富致力于引领企业莲勒发展，在竞争激烈的市场中脱颖而出，创造持久的成功。
      </div>
      <div class="about-list">
        <div class="about-item">
          <div class="about-icon">
            <img src="../../../assets/index/about1.png" alt="" />
          </div>
          <div class="about-name">创新技术</div>
        </div>
        <div class="about-item">
          <div class="about-icon">
            <img src="../../../assets/index/about2.png" alt="" />
          </div>
          <div class="about-name">专业团队</div>
        </div>
        <div class="about-item">
          <div class="about-icon">
            <img src="../../../assets/index/about3.png" alt="" />
          </div>
          <div class="about-name">高效服务</div>
        </div>
      </div>
    </div>
    <div class="content-advice" id="advice">
      <div class="advice-left">
        <div class="advice-title">助力企业高效增长</div>
        <div class="advice-x"></div>
      </div>
      <div class="advice-right">
        <div class="advice-form">
          <div class="form-title">咨询我们</div>
          <div class="form-text">我们会在2个工作日内与您联系</div>
          <div class="form-item">
            <div class="item-label">* 您的姓名</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.name"
                placeholder="请输入您的姓名"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 您的联系方式</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.mobile"
                placeholder="请输入您的联系方式"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 公司名称</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.company"
                placeholder="请输入您的公司名称"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 咨询内容</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.consult"
                placeholder="请输入您要咨询的内容"
              />
            </div>
            <!-- <div class="form-textarea">
              <textarea
                type="textarea"
                v-model="from.consult"
                placeholder="请输入您要咨询的内容"
              ></textarea>
            </div> -->
          </div>

          <div
            :class="['form-btn', { 'form-submit-none': !isDisplay }]"
            @click="isDisplay ? handleSubmit() : null"
            style="margin-bottom: 30px"
          >
            立即咨询
          </div>

          <div class="form-privacy">
            <el-checkbox v-model="checked"></el-checkbox> 同意<span
              @click="goPages('Privacy')"
              >《隐私协议》</span
            >
          </div>
        </div>
      </div>
    </div>

    <Filings />

    <div class="password-popup" v-if="formState" @click="formState = false">
      <div class="password" @click.stop>
        <div class="advice-form">
          <div class="form-title">咨询我们</div>
          <div class="form-text">我们会在2个工作日内与您联系</div>
          <div class="form-item">
            <div class="item-label">* 您的姓名</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.name"
                placeholder="请输入您的姓名"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 您的联系方式</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.mobile"
                placeholder="请输入您的联系方式"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 公司名称</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.company"
                placeholder="请输入您的公司名称"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="item-label">* 咨询内容</div>
            <div class="item-input">
              <input
                type="text"
                v-model="from.consult"
                placeholder="请输入您要咨询的内容"
              />
            </div>
            <!-- <div class="form-textarea">
              <textarea
                type="textarea"
                v-model="from.consult"
                placeholder="请输入您要咨询的内容"
              ></textarea>
            </div> -->
          </div>
          <div
            :class="['form-btn', { 'form-submit-none': !isDisplay }]"
            @click="isDisplay ? handleSubmit() : null"
            style="margin-bottom: 20px"
          >
            立即咨询
          </div>

          <div class="form-privacy">
            <el-checkbox v-model="checked"></el-checkbox> 同意<span
              @click="goPages('Privacy')"
              >《隐私协议》</span
            >
          </div>
        </div>
        <div class="password-close" @click="formState = false">
          <img src="../../../assets/icon/close.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 协议 -->
    <AgreementView
      v-if="agreement"
      :show.sync="agreement"
      @on-change="checked = true"
    />
  </div>
</template>

<script>
import Swiper from "swiper";
import { HTTP } from "../../../utils/request";
import CaseView from "./components/case.vue";
import AgreementView from "./components/agreement.vue";
import { isPC } from "../../../utils/common";

const URL = {
  list: "api/common/productList",
  leaveWorld: "api/operation/leaveWorld",
};
export default {
  components: {
    CaseView,
    AgreementView,
  },
  data() {
    return {
      isPC,
      from: {
        name: "",
        mobile: "",
        company: "",
        consult: "",
      },
      checked: false,
      list: [],
      screenIndex: 0,
      screenWidth: 0,
      screenHeight: 0,
      formState: false,
      agreement: false,
    };
  },
  computed: {
    isDisplay() {
      return (
        !!this.from.name &&
        !!this.from.mobile &&
        !!this.from.company &&
        !!this.from.consult &&
        !!this.checked
      );
    },
  },
  mounted() {
    // 在JavaScript中获取当前窗口宽度
    this.screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    !isPC() && this.zoom(this.screenWidth / 1440);

    this.screenHeight = this.screenWidth / 1440;

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        !isPC() && this.zoom(this.screenWidth / 1440);
      },
      false
    );
    this.$nextTick(() => {
      new Swiper(this.$refs.cur, {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          //点击小球的时候也切换图片
          clickable: true,
        },
        on: {
          click: () => {
            this.formState = true;
          },
        },
      });
    });
    this.getList();
  },
  methods: {
    zoom(scale) {
      const dom = document.getElementById("content");
      dom.style.transform = "scale(" + scale + ")";
      dom.style.transformOrigin = "top left";
      dom.style.width = 100 / scale + "%";
      dom.style.height = 100 / scale + "%";
    },

    goAnchorParent(selector, index) {
      this.screenIndex = index;
      window.scrollTo({
        top:
          (document.getElementById(selector)?.offsetTop - 70) *
          this.screenHeight,
        behavior: "smooth",
      });
    },
    goPages(name) {
      switch (name) {
        case "Privacy":
          // window.open("http://www.zealytech.com/#/privacy");
          this.agreement = true;
          break;
        default:
          this.$router.push({
            name,
          });
          break;
      }
    },
    async getList() {
      const res = await HTTP({
        url: URL.list,
        method: "get",
      });
      this.list = res || [];
    },
    async handleSubmit() {
      try {
        await HTTP({
          url: URL.leaveWorld,
          method: "post",
          data: {
            ...this.from,
          },
        });
        this.$message.success("提交成功");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  // overflow: hidden;
  background-color: #fff;

  .content-head {
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background-color: #fff;
    .head-view {
      width: calc(100% - (136px * 2));
      height: 70px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .head-left {
        display: flex;
        align-items: center;
        .head-image {
          width: 133px;
          height: 38px;
          overflow: hidden;
          //   background-color: #eee;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .head-menu {
          display: flex;
          align-items: center;
          height: 70px;
          margin-left: 81px;
          .menu-item {
            height: 70px;
            position: relative;
            overflow: hidden;
            border-bottom: 2px solid #fff;
            margin-right: 58px;
            &:last-child {
              margin-right: 0;
            }
            .item-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #999999;
              line-height: 70px;
              cursor: pointer;
            }
          }
          .item-active {
            border-bottom: 2px solid #fd8419;
            .item-text {
              color: #111111;
            }
          }
        }
      }
      .head-right {
        display: flex;
        align-items: center;
        .right-item {
          width: 105px;
          height: 38px;
          border-radius: 21px;
          background-color: #fd8419;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 19px;
          cursor: pointer;
        }
      }
    }
  }
  .swiper-container {
    width: 100%;
    // height: 525px;
    .swiper-slide {
      width: 100%;
      //   height: 525px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    ::v-deep .swiper-pagination-bullet {
      width: 20px;
      height: 2px;
      background: #fff;
      border-radius: 0;
      opacity: 0.4;
    }
    ::v-deep .swiper-pagination-bullet-active {
      width: 50px;
      height: 2px;
      background: #ffffff;
      border-radius: 0;
      opacity: 1;
    }
  }
  .content-classify {
    width: 100%;
    overflow: hidden;
    .classify-title {
      text-align: center;
      margin-top: 51px;
    }
    .classify-list {
      width: 100%;
      margin: auto {
        top: 61px;
        bottom: 75px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      .classify-item {
        width: 277px;
        height: 347px;
        background: #ffffff;
        box-shadow: 0px 3px 23px 0px rgba(0, 0, 0, 0.06);
        border-radius: 9px;
        border: 2px solid #fff;
        margin-right: 21px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          border: 2px solid #ff7800;
        }
        .item-icon {
          width: 60px;
          height: 50px;
          margin: auto {
            top: 39px;
          }
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 100%;
          }
        }
        .item-name {
          text-align: center;
          font-size: 17px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4e4e4e;
          line-height: 23px;
          margin-top: 14px;
        }
        .item-text {
          width: 100%;
          height: 36px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4e4e4e;
          line-height: 18px;
          text-align: center;
          margin-top: 12px;
        }
        .item-x {
          width: 240px;
          height: 1px;
          border-bottom: 1px dotted #e5e5e5;
          margin: auto {
            top: 15px;
            bottom: 18px;
          }
        }
        .item-cell {
          .cell {
            display: flex;
            align-items: center;
            margin-left: 28px;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .cell-icon {
              width: 19px;
              height: 19px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
            .cell-text {
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #4e4e4e;
              line-height: 17px;
              margin-left: 9px;
            }
          }
        }
      }
    }
  }
  .content-case {
    width: 100%;
    overflow: hidden;
    background-color: #f7fafc;
    .case-title {
      text-align: center;
      margin-top: 60px;
    }
    .case-text {
      font-size: 23px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      text-align: center;
      margin-top: 26px;
    }

    .case-content {
      width: calc(358px + 575px);
      margin: auto {
        top: 60px;
      }
      display: flex;
      align-items: center;
      .case-item {
        width: 358px;
        height: 230px;
        background: linear-gradient(146deg, #4e99ef 0%, #4e6bef 100%);
        border-radius: 9px;
        overflow: hidden;
        .item-name {
          margin-top: 43px;
          height: 68px;
          font-size: 22px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
          margin-left: 39px;
        }
        .item-x {
          margin-left: 39px;
          margin-top: 14px;
          width: 52px;
          height: 4px;
          background: #ffffff;
        }
        .item-text {
          margin-left: 39px;
          margin-top: 27px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          line-height: 21px;
        }
      }
    }
    .case-list {
      display: flex;
      align-items: center;
      margin-top: 89px;

      .case-item {
        width: 355px;
        height: 346px;
        background-image: url("../../../assets/index/case1.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        margin-right: 33px;
        flex-shrink: 0;
        .item-name {
          height: 84px;
          font-size: 26px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #ffffff;
          line-height: 42px;
          margin-left: 39px;
          margin-top: 58px;
        }
        .item-x {
          margin-left: 39px;
          margin-top: 22px;
          width: 50px;
          height: 5px;
          background: #ffffff;
        }
        .item-text {
          margin-left: 39px;
          margin-top: 30px;
          height: 75px;
          font-size: 21px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
        }
      }
      .case-cell {
        // padding-left: 136px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;

        .cell {
          width: 349px;
          height: 345px;
          background: #ffffff;
          padding: 0 45px 0 37px;
          position: relative;
          margin-right: 33px;
          flex-shrink: 0;
          &:last-child {
            margin-right: 0;
          }
          .cell-name {
            font-size: 23px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #111111;
            line-height: 32px;
            margin-top: 54px;
          }
          .cell-text {
            font-size: 15px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            margin-top: 33px;
          }
          .cell-icon {
            width: 81px;
            height: 66px;
            position: absolute;
            right: 34px;
            bottom: 34px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .case-image {
      width: calc(100% - (144px * 2));
      margin: auto {
        top: 68px;
        bottom: 61px;
      }
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .content-price {
    width: 100%;
    overflow: hidden;
    .price-title {
      text-align: center;
      margin-top: 71px;
    }
    .price-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      // padding-bottom: 134px;
      .price-item {
        width: 327px;
        background: #f7fafc;
        margin-right: 59px;
        &:last-child {
          margin-right: 0;
        }
        .item-head {
          width: 100%;
          height: 60px;
          background: linear-gradient(146deg, #4e99ef 0%, #4e6bef 100%);
          border-radius: 3px 3px 0px 0px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .item-head1 {
          background: linear-gradient(145deg, #ffa66f 0%, #ff7800 100%);
        }
        .item-head2 {
          background: linear-gradient(322deg, #ff351f 0%, #ff2370 100%);
        }
        .item-cell {
          padding: 13px 23px;
          .cell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 9px;
            .cell-label {
              height: 18px;
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #4e4e4e;
              line-height: 18px;
            }
            .cell-value {
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #4e4e4e;
              .value-icon {
                width: 17px;
                height: 12px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }
            }
          }
        }
        .item-x {
          width: 281px;
          height: 1px;
          border-bottom: 1px dotted #e5e5e5;
          margin: auto {
            top: 22px;
          }
        }
        .item-price {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 30px;
          span {
            font-size: 29px;
            font-family: DINPro-Bold, DINPro;
            font-weight: bold;
            color: #333333;
          }
        }
        .item-btn {
          width: 173px;
          height: 42px;
          background: linear-gradient(135deg, #ffa250 0%, #ff7800 100%);
          border-radius: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          margin: auto {
            top: 25px;
            bottom: 36px;
          }
        }
      }
    }
    .price-illustrate {
      margin: 30px 0 70px 171px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
    }
  }
  .content-about {
    width: 100%;
    background: #f7fafc;
    overflow: hidden;
    .about-title {
      text-align: center;
      margin-top: 71px;
    }
    .about-text {
      text-align: center;
      font-size: 17px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 32px;
      margin-top: 15px;
    }
    .about-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      margin-bottom: 134px;
      .about-item {
        width: 277px;
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 20px 23px 0px #edeff4;
        border-radius: 9px;
        margin-right: 37px;
        &:last-child {
          margin-right: 0;
        }
        .about-icon {
          width: 62px;
          height: 62px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto {
            top: 49px;
            bottom: 15px;
          }
          img {
            width: 100%;
          }
        }
        .about-name {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }
  .content-advice {
    display: flex;
    & > div {
      flex: 1;
      height: 740px;
    }
    .advice-left {
      background-image: url("https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/image/index_customer_leads.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      .advice-title {
        font-size: 42px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #000000;
        line-height: 58px;
        margin-top: 158px;
      }
      .advice-x {
        width: 101px;
        height: 5px;
        background: #000000;
        margin: auto {
          top: 30px;
        }
      }
    }
    .advice-right {
      display: flex;
      align-items: center;
      justify-content: center;
      .advice-form {
        width: 585px;
        .form-title {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 42px;
        }
        .form-text {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin-top: 12px;
          margin-bottom: 38px;
        }
        .form-item {
          width: 100%;
          margin-bottom: 30px;
          .item-label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
          .item-input {
            margin-top: 10px;
            width: 585px;
            height: 45px;
            background: #ffffff;
            border-radius: 3px;
            border: 1px solid #e6e6e6;
            background: #ffffff;
            border-radius: 3px;
            input {
              width: 100%;
              height: 100%;
              padding-left: 10px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              background-color: #f6f9ff;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          }
          .form-textarea {
            height: 90px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            background-color: #fff;
            margin-top: 15px;
            textarea {
              width: 100%;
              height: 100%;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              background-color: #f6f9ff;
              padding: 10px;
            }
          }
        }
        .form-btn {
          width: 585px;
          height: 45px;
          background: linear-gradient(135deg, #ffa250 0%, #ff7800 100%);
          border-radius: 3px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .form-submit-none {
          opacity: 0.7;
          cursor: no-drop;
        }
      }
    }
  }
}
.content-title {
  font-size: 36px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #0f2420;
  line-height: 61px;
}

.password-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .password {
    width: 684px;
    height: 660px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .advice-form {
      width: 385px;
      .form-title {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 42px;
      }
      .form-text {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 12px;
        margin-bottom: 38px;
      }
      .form-item {
        width: 100%;
        margin-bottom: 20px;
        .item-label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
        }
        .item-input {
          margin-top: 10px;
          width: 385px;
          height: 35px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #e6e6e6;
          background: #ffffff;
          border-radius: 3px;
          input {
            width: 100%;
            height: 100%;
            padding-left: 10px;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            background-color: #f6f9ff;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
        .form-textarea {
          height: 90px;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          background-color: #fff;
          margin-top: 15px;
          textarea {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            background-color: #f6f9ff;
            padding: 10px;
          }
        }
      }
      .form-btn {
        width: 385px;
        height: 45px;
        background: linear-gradient(135deg, #ffa250 0%, #ff7800 100%);
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 30px;
      }
      .form-submit-none {
        opacity: 0.7;
        cursor: no-drop;
      }
    }

    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.form-privacy {
  font-size: 13px;
  color: #333333;
  text-align: center;
  span {
    cursor: pointer;
    color: #0066fe;
  }
}
</style>
